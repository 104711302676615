import VirtualList from "@/components/VirtualList";
import useDeepComparison from "@/hooks/useDeepComparison";
import { InputFieldType, formFieldError } from "@/types";
import customMatchSort from "@/utils/customMatch";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteOwnerState,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  CircularProgress,
  FilterOptionsState,
  OutlinedInput,
  createFilterOptions,
} from "@mui/material";
import * as React from "react";
import {
  FieldValues,
  UseControllerProps,
  useController,
  useWatch,
} from "react-hook-form";
import CustomFormControl from "../../FormControl/CustomFormControl";
import { ExtraButtonsWithInputLabel } from "../inputfields.interface";

export default function CustomAutocomplete<T extends FieldValues>({
  label = null,
  control,
  itemList: options = [],
  name,
  required,
  loading = false,
  error,
  type = "primary",
  disabled = false,
  placeholder,
  valueContainsLabel = false,
  isMultiple = false,
  onSearch,
  onChange,
  readOnly = false,
  disabledValues = [],
  inputType = "text",
  allowCustomValues = false,
  customMatcher = false,
  toolTip = null,
  virtualize = false,
  disableClearable = false,
  stringToRenderBeforeValue,
  noMenuAllowed,
  customIsOptionEqualToValue,
  isCustomList = false,
  renderTags,
  listboxProps,
  customList,
  extraLabelButtons,
  isWatch = false,
  isRetainInputValue = false,
}: CustomAutocompleteProps<T>) {
  const [inputValue, setInputValue] = React.useState("");
  const isChanged = useDeepComparison(options);

  const values = React.useMemo(
    () => options.map((o: any) => o.value),
    [isChanged]
  );

  const valueKeyMapping = React.useMemo(() => {
    const obj: any = {};
    options.forEach((item: any) => (obj[item.value] = item.label));
    return obj;
  }, [values]);

  function getLabel(value: any) {
    if (valueContainsLabel) {
      return value.label;
    } else if (value) {
      if (valueKeyMapping[value]) {
        return valueKeyMapping[value];
      }
      return "";
    } else {
      return "";
    }
  }

  const secondaryStyles =
    type === "secondary"
      ? {
          backgroundColor: "background.default",
        }
      : {};

  const { field } = useController({ control, name });
  const watchedValue = useWatch({
    name,
    control,
    disabled: !isWatch,
  });

  React.useEffect(() => {
    if (isWatch) {
      if (field.value !== watchedValue) {
        field.onChange(watchedValue);
      }
    }
  }, [watchedValue]);

  const handleOnChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason
  ) => {
    const inputValue = (event.target as HTMLInputElement).value;
    let requiredValue = value;
    if (!isMultiple && isRetainInputValue) {
      setInputValue(valueKeyMapping[value]);
    }
    if ((event as React.KeyboardEvent<Element>)["code"] === "Enter") {
      if (allowCustomValues && valueContainsLabel) {
        const customValue = {
          label: inputValue,
          value: inputValue,
        };
        if (isMultiple) {
          const filteredValue = value.filter((x: unknown) => x !== inputValue);
          if (isRetainInputValue) {
            setInputValue("");
          }
          //checking if the value already exists
          if (
            typeof customValue === "object" &&
            !filteredValue.some((x: any) => x.value === customValue.value)
          ) {
            requiredValue = [...filteredValue, customValue];
          } else {
            requiredValue = filteredValue;
          }
        } else {
          requiredValue = customValue;
        }
      }
    }

    field.onChange(requiredValue);
    if (onChange) onChange(requiredValue, reason);
  };

  const handleOnInputChange: React.FormEventHandler<HTMLDivElement> = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const result = (event.target as HTMLInputElement).value;
    if (isRetainInputValue) {
      setInputValue(result);
    }
    if (onSearch) onSearch(result, name);
  };

  function getValue() {
    if (isMultiple) {
      if (valueContainsLabel) {
        if (field.value?.length) return field.value;
        else return [];
      } else {
        if (field.value)
          return Array.isArray(field.value) ? field.value : [field.value];
        else return [];
      }
    } else {
      if (valueContainsLabel) {
        if (field.value?.label) return field.value;
        else
          return {
            label: "",
            value: "",
          };
      } else {
        if (field.value) return field.value;
        else return null;
      }
    }
  }

  const filterOptions = (
    options: any[],
    { inputValue }: FilterOptionsState<any>
  ) => {
    return customMatchSort(options, inputValue);
  };

  const isValueSelected = (option: any, value: any) => {
    return Boolean(option.value === value.value);
  };

  const dynamicProps: Record<string, any> = {};
  if (!virtualize && isCustomList) {
    dynamicProps["ListboxComponent"] = customList;
    dynamicProps["ListboxProps"] = listboxProps;
  }
  if (renderTags) {
    dynamicProps["renderTags"] = renderTags;
  }
  if (virtualize) {
    dynamicProps["renderOption"] = (props: object, option: any, state: any) =>
      [
        {
          props,
          option,
          state: state.index,
          label: valueKeyMapping[option],
        },
      ] as React.ReactNode;
    dynamicProps["ListboxComponent"] = VirtualList;
  }
  if (valueContainsLabel) {
    dynamicProps["isOptionEqualToValue"] = isValueSelected;
  }
  if (noMenuAllowed != undefined) dynamicProps["open"] = noMenuAllowed;
  if (customIsOptionEqualToValue)
    dynamicProps["isOptionEqualToValue"] = customIsOptionEqualToValue;
  const dynamicInputProps = (
    params: AutocompleteRenderInputParams["inputProps"]
  ) => {
    return stringToRenderBeforeValue
      ? {
          value: `${stringToRenderBeforeValue}: ${params.value}`,
        }
      : {};
  };

  if (isRetainInputValue) {
    dynamicProps["inputValue"] = inputValue;
  }

  return (
    <>
      <Autocomplete
        multiple={isMultiple}
        disableCloseOnSelect={isMultiple}
        freeSolo={allowCustomValues}
        loading={loading}
        options={valueContainsLabel ? options : values}
        filterOptions={customMatcher ? filterOptions : createFilterOptions()}
        getOptionLabel={getLabel}
        getOptionDisabled={(option) => disabledValues.includes(option)}
        popupIcon={<ExpandMoreOutlinedIcon />}
        onInput={handleOnInputChange}
        readOnly={readOnly}
        renderInput={(params) => {
          const { InputProps, inputProps, ...rest } = params;
          return (
            <CustomFormControl
              label={label}
              required={required}
              error={error}
              toolTip={toolTip}
              disabled={disabled}
              extraButtons={extraLabelButtons}
            >
              <OutlinedInput
                {...rest}
                {...InputProps}
                placeholder={placeholder}
                type={inputType}
                endAdornment={
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {InputProps.endAdornment}
                  </React.Fragment>
                }
                inputProps={{
                  ...inputProps,
                  ...dynamicInputProps(inputProps),
                }}
              />
            </CustomFormControl>
          );
        }}
        value={getValue()}
        onChange={handleOnChange}
        disabled={disabled}
        disableClearable={disableClearable}
        sx={{
          ".MuiAutocomplete-input": {
            padding: "0 !important",
            ...secondaryStyles,
          },
          ".MuiOutlinedInput-root": {
            padding: "0 0 0 20px",
            ...secondaryStyles,
          },
        }}
        {...dynamicProps}
      />
    </>
  );
}

export type TCustomAutocomplete = {
  label?: string | null;
  type?: InputFieldType;
  itemList?: any[];
  required?: boolean;
  error?: formFieldError;
  placeholder?: string | undefined;
  disabled?: boolean;
  valueContainsLabel?: boolean;
  isMultiple?: boolean;
  loading?: boolean;
  onSearch?: any;
  onChange?: (value: any, reason: AutocompleteChangeReason) => void;
  readOnly?: boolean;
  disabledValues?: string[];
  allowCustomValues?: boolean;
  inputType?: string;
  toolTip?: string | null;
  customMatcher?: boolean;
  virtualize?: boolean;
  disableClearable?: boolean;
  stringToRenderBeforeValue?: string;
  noMenuAllowed?: boolean;
  customIsOptionEqualToValue?: (option: any, value: any) => boolean;
  isCustomList?: boolean;
  renderTags?: (
    value: any[],
    getTagProps: AutocompleteRenderGetTagProps,
    ownerState: AutocompleteOwnerState<any, boolean, boolean, boolean, "div">
  ) => React.ReactNode;
  listboxProps?: any;
  extraLabelButtons?: ExtraButtonsWithInputLabel[];
  isWatch?: boolean;
  customList?: any;
  isRetainInputValue?: boolean;
};

type CustomAutocompleteProps<T extends FieldValues = FieldValues> =
  UseControllerProps<T> & TCustomAutocomplete;
