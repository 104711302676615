import { useCallback, useEffect, useRef, useState } from "react";

export default function useDeepComparison(
  values: Array<
    | string
    | number
    | {
        label: string;
        value: string | number;
      }
  >,
  compareFunction?: (a: any, b: any) => boolean
) {
  const [isChanged, setIsChanged] = useState(false);
  const previousValues = useRef(values);
  const isEqual = useCallback(
    compareFunction
      ? compareFunction
      : (a: any, b: any) => {
          if (typeof a !== typeof b) return false;
          if (typeof a === "object" && a !== null && b !== null) {
            const keysA = Object.keys(a);
            const keysB = Object.keys(b);
            if (keysA.length !== keysB.length) return false;
            for (const key of keysA) {
              if (!isEqual(a[key], b[key])) return false;
            }
            return true;
          }
          return a === b;
        },
    [compareFunction]
  );
  useEffect(() => {
    for (let i = 0; i < values.length; i++) {
      if (!isEqual(values[i], previousValues.current[i])) {
        setIsChanged(!isChanged);
        previousValues.current = values;
        break;
      }
    }
  }, [values]);

  return isChanged;
}
