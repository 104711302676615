import BlueWhaleResearchLoaderLogo from "@/assets/logo/bluewhaleresearch-loaderlogo.png";
import BlueWhaleResearchLogo from "@/assets/logo/bluewhaleresearch-logo.png";
import BlueWhaleResearchMiniLogo from "@/assets/logo/bluewhaleresearch-miniLogo.png";
import ContanuityLogo from "@/assets/logo/contanuity-logo.png";
import ContanuityMiniLogo from "@/assets/logo/contanuity-miniLogo.png";
import DemandScienceLogo from "@/assets/logo/demandscience-logo.png";
import DemandScienceMiniLogo from "@/assets/logo/demandscience-miniLogo.png";
import IntentsifyLogo from "@/assets/logo/Intentsify-logo.png";
import IntentsifyMiniLogo from "@/assets/logo/Intentsify-miniLogo.png";
import LeadspaceIcon from "@/assets/logo/leadspace-icon.png";
import LeadspaceLogoBlack from "@/assets/logo/leadspace-logo-black.png";
import LeadspaceLogoWhite from "@/assets/logo/leadspace-logo-white.png";
import ProspectBaseIcon from "@/assets/logo/prospectbase-icon-white.svg";
import ProspectBaseLogoBlack from "@/assets/logo/prospectbase-logo-black.svg";
import ProspectBaseLogoWhite from "@/assets/logo/prospectbase-logo-white.svg";

const config = {
  CONTANUITY: {
    theme: "tertiary",
    brand: {
      logo: ContanuityLogo,
      miniLogo: ContanuityMiniLogo,
      loaderLogo: null,
      name: "Contanuity",
      title: "Contanuity",
      favFolderName: "contanuity",
    },
    pixel: {
      url: "https://tracking.contanuity.com",
      identifierName: "CONTANUITY_TAG",
      tagPath: "/tag.js",
    },
  },
  DEMANDSCIENCE: {
    theme: "default",
    brand: {
      logo: DemandScienceLogo,
      miniLogo: DemandScienceMiniLogo,
      loaderLogo: null,
      name: "Demand Science",
      title: "ABM Display by DemandScience",
      favFolderName: "demand-science",
    },
    pixel: {
      url: "https://abm-tracking.demandscience.com",
      identifierName: "TAG_INFO",
      tagPath: "/tag.js",
    },
  },
  INTENTSIFY: {
    theme: "tertiary",
    brand: {
      logo: IntentsifyLogo,
      miniLogo: IntentsifyMiniLogo,
      loaderLogo: null,
      name: "Intentsify",
      title: "Intentsify",
      favFolderName: "intentsify",
    },
    pixel: {
      url: "https://tracking.contanuity.com",
      identifierName: "CONTANUITY_TAG",
      tagPath: "/tag.js",
    },
  },
  BLUEWHALERESEARCH: {
    theme: "tertiary",
    brand: {
      logo: BlueWhaleResearchLogo,
      miniLogo: BlueWhaleResearchMiniLogo,
      loaderLogo: BlueWhaleResearchLoaderLogo,
      name: "BlueWhale Research",
      title: "BlueWhale Research",
      favFolderName: "bluewhale-research",
    },
    pixel: {
      url: "https://signal.bluewhaleresearch.com",
      identifierName: "BWR_TAG",
      tagPath: "/u01AegDdnh/tag.js",
    },
  },
  PROSPECTBASE: {
    theme: "tertiary",
    brand: {
      logo: ProspectBaseLogoWhite,
      miniLogo: ProspectBaseIcon,
      loaderLogo: ProspectBaseLogoBlack,
      name: "Prospect Base",
      title: "Prospect Base",
      favFolderName: "prospect-base",
    },
    pixel: {
      url: "https://tracking.prospectbase.com",
      identifierName: "PB_TAG",
      tagPath: "/qCz7yH0dYI/tag.js",
    },
  },
  LEADSPACE: {
    theme: "tertiary",
    brand: {
      logo: LeadspaceLogoWhite,
      miniLogo: LeadspaceIcon,
      loaderLogo: LeadspaceLogoBlack,
      name: "Leadspace",
      title: "Leadspace",
      favFolderName: "leadspace",
    },
    pixel: {
      url: "https://radar.leadspace.com",
      identifierName: "LS_TAG",
      tagPath: "/m2vR10uCZP/tag.js",
    },
  },
};

export default config;
