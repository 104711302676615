import { stringSimilarity } from "string-similarity-js";

export default function customMatchSort(options: any[], inputValue: string) {
  if (!inputValue.length) return options;
  const val = inputValue.trim().split(" ");
  const list = [];

  for (const option of options) {
    const value = option.value ? option.value : option;
    const exists = val.every((x) => {
      return value
        .toLowerCase()
        .split(" ")
        .filter((x: any) => x)
        .some((z: string) => z.includes(x.toLowerCase()));
    });

    if (exists) {
      list.push({
        option,
        similarity: stringSimilarity(value, inputValue),
      });
    }
  }

  list.sort((a, b) => b.similarity - a.similarity);
  return list.map((item) => item.option);
}
